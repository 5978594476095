'use strict';

angular.module('kerp-forms.forms').factory('FCA_CONDITIONS', ['FormConditions', function (FormConditions) {

  const effectiveDateIsBeforeAgeOfMajority = function(ctx) {
    const backdateRequested = ctx.lib.getModelValue('backdateRequested');
    if (!backdateRequested) {
      return false;
    }

    const effectiveDate = ctx.lib.getModelValue('effectiveDate');
    const dateOfBirth = ctx.lib.getModelValue('person.details.dob');

    const effectiveDateM = moment(effectiveDate, 'YYYY-MM-DD');
    const dateOfBirthM = moment(dateOfBirth, 'DD/MM/YYYY');

    return dateOfBirthM.add(18,'y').isAfter(effectiveDateM);
  };

  return FormConditions.clone({
    conditions: {
      'agent.applicantConfirmationDeclaration': function () {
        return this.lib.isAnyOf('agent.details.relationship', ['advocate', 'friend']);
      },
      'agent.agentDeclaration': function () {
        return this.lib.isAnyOf('agent.details.relationship',
          ['appointeeship', 'powerOfAttorney', 'lastingPowerOfAttorney', 'deputyship']);
      },
      'person.expenses.rent': function () {
        return !this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedByPartner', 'ownedJointly']);
      },
      'person.expenses.mortgage': function () {
        return !this.lib.isAnyOf('person.property.ownership', ['councilTenant', 'rentedPrivately']);
      },

      'effectiveDateIsBeforeAgeOfMajority': function () {
        return effectiveDateIsBeforeAgeOfMajority(this);
      },

      'effectiveDateIsNotBeforeAgeOfMajority': function () {
        return !(effectiveDateIsBeforeAgeOfMajority(this));
      }

    }
  });
}]);
